<template>
    <div class="container-fluid">
        <div class="container">
            <b-row class=" no-gutters height-self-center">
                <b-col sm="12" class=" text-center align-self-center">
                    <div class="iq-error position-relative">
                        <img src="../../../assets/images/nodata.png" class="img-fluid iq-error-img" alt="">
                        <h2 class="mb-0">There are no invoices for this order.</h2>
                    </div>
                </b-col>
            </b-row>
            <!-- <div class="row">
                <div class="col-lg-12">
                    <h3 class="mb-4">Invoices</h3>
                    <invoiceList  v-for="Purchase in listInvoices[0].items" :key="Purchase.increment_id" :number='Purchase.increment_id' :date="Purchase.created_at" :total="Purchase.grand_total" />
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import invoiceList from '../../../components/omni/invoice-list'
export default {
    name: 'InvoiceList',
    components: {
        // invoiceList
    },
    computed: {
        ...mapGetters({
            getInvoices: 'invoices/invoices'
        }),
        hasInvoices() {
            if (this.getInvoices) {
                return true
            } else {
                return false
            }
        },
        listInvoices() {
            return this.getInvoices
        }
    },
    beforeMount() {
        this.$store.dispatch('invoices/fetchInvoiceList', {
            orderId: this.$route.params.id
        });
    }
}
</script>